import {
  ComponentRef,
  Inject,
  Injectable,
  ViewContainerRef,
} from '@angular/core';
import {
  CallServiceState,
  EventLogExtendedState,
  EventLogState,
  ExpansionListState,
  NotesState,
  PbxState,
  SetStateOptions,
} from '../shared/state.interfaces';
import {
  BehaviorSubject,
  Observable,
  catchError,
  distinctUntilChanged,
  map,
  of,
  take,
} from 'rxjs';
import { RightDrawerService } from '../right-drawer.service';
import {
  EventLogHistoryType,
  EventLogMode,
  EventLogSubheader,
} from '../shared/event-log.enums';
import * as _ from 'lodash-es';
import { ClientService } from '@app/core/services/client.service';
import { CreditService } from '@app/core/services/credit.service';
import { Credit } from '@app/core/types/credit';
import {
  Call,
  FORMATTED_HIDDEN_PHONE_NUMBER,
  FORMATTED_INQUIRY_PHONE_NUMBER,
  HIDDEN_PHONE_NUMBER,
  INQUIRY_PHONE_NUMBER,
} from '@app/core/types/call';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterGroupType } from '@app/main/filter/types';
import { ClientStatusNames } from '@app/core/types/client-status';
import { Client } from '@app/core/types/client';
import { EventLogComponent } from './event-log.component';
import { NotificationService } from '@app/core/services/notification.service';
import { Router } from '@angular/router';
import { Page } from '@app/core/types/page';

@Injectable({
  providedIn: 'root',
})
export class EventLogStateService {
  private state: EventLogExtendedState;
  private creditsRequestId: number = 0;
  public isFetchingCredits: boolean = false;
  public shouldFetchCredits: boolean = true;
  private state$ = new BehaviorSubject<EventLogExtendedState>(
    this.initialState()
  );
  private logButtonVisible$ = new BehaviorSubject<boolean>(false);

  constructor(
    private rightDrawer: RightDrawerService,
    private clientService: ClientService,
    private creditService: CreditService,
    private notifications: NotificationService,
    private router: Router
  ) {
    this.state = this.initialState();
    this.setState = this.setState.bind(this);
  }
  getExpansionListState(): Observable<ExpansionListState> {
    return this.state$.asObservable().pipe(
      map((state) => ({
        openedCredit: state.openedCredit,
        selectedCredit: state.selectedCredit,
        phoneNumber:
          this.formatPhoneNumber(state.phoneNumber) ||
          this.formatPhoneNumber(state.client?.mobile) ||
          this.formatPhoneNumber(state.openedCredit?.user?.mobile),
        call: this.state?.call,
        creditContextArr: this.state?.creditContextArr,
      })),
      distinctUntilChanged(_.isEqual)
    );
  }

  getCallServiceState(): CallServiceState {
    const state = {
      phoneNumber:
        this.formatPhoneNumber(this.state.phoneNumber) ||
        this.formatPhoneNumber(this.state.client?.mobile) ||
        this.formatPhoneNumber(this.state.openedCredit?.user?.mobile),
      openedCredit: this.state.openedCredit,
      selectedCredit: this.state.selectedCredit,
      creditContextArr: this.state.creditContextArr,
      call: this.state.call,
    };

    return state;
  }

  getGlobalState(): any {
    return this.state$.asObservable().pipe(
      map((state) => ({
        openedCredit: state.openedCredit,
        call: state.call,
        client: state.client || state.openedCredit?.user,
      })),
      distinctUntilChanged(_.isEqual)
    );
  }

  getEventLogState(): Observable<EventLogState> {
    return this.state$.asObservable().pipe(
      map((state) => ({
        mode: state.mode,
        subheader: state.subheader,
        historyType: state.historyType,
        openedCredit: state.openedCredit,
        call: state.call,
        client: state.client || state.openedCredit?.user,
        clientFoundByMobile: state.clientFoundByMobile,
        phoneNumber:
          this.formatPhoneNumber(state.phoneNumber) ||
          this.formatPhoneNumber(state.client?.mobile) ||
          this.formatPhoneNumber(state.openedCredit?.user?.mobile),
      })),
      distinctUntilChanged(_.isEqual)
    );
  }

  getPbxState(): Observable<PbxState> {
    return this.state$.asObservable().pipe(
      map((state) => ({
        mode: state.mode,
        creditContextArr: state.creditContextArr,
        historyType: state.historyType,
        openedCredit: state.openedCredit,
        selectedCredit: state.selectedCredit,
        client: state.client || state.openedCredit?.user,
        clientFoundByMobile: state.clientFoundByMobile,
        phoneNumber:
          this.formatPhoneNumber(state.phoneNumber) ||
          this.formatPhoneNumber(state.client?.mobile) ||
          this.formatPhoneNumber(state.openedCredit?.user?.mobile),
        call: state.call,
        failedCallData: state.failedCallData,
      })),
      distinctUntilChanged(_.isEqual)
    );
  }

  formatPhoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      phoneNumber = phoneNumber.trim();

      if (phoneNumber.startsWith('+359')) {
        return '0' + phoneNumber.substring(4);
      } else if (phoneNumber.startsWith('+')) {
        // For foreign countries
        return '00' + phoneNumber.substring(1);
      } else {
        // If it already starts with '0'
        return phoneNumber;
      }
    }

    return null;
  }

  getNotesState(): Observable<NotesState> {
    return this.state$.asObservable().pipe(
      map((state) => ({
        selectedCredit: state.selectedCredit,
        openedCredit: state.openedCredit,
        call: state.call,
      })),
      distinctUntilChanged(_.isEqual)
    );
  }

  // ns - New state
  setState(ns: Partial<EventLogExtendedState>, options: SetStateOptions = {}) {
    if (!ns || Object.keys(ns).length === 0) return;

    const shouldFetchClientCredits = this.shouldFetchClientCredits(
      ns,
      this.state,
      options.isInCreditEdit
    );
    const formattedPhoneNumber = this.getBulgarianPhoneNumberWithCountryCode(
      ns.phoneNumber
    );

    this.updateState(ns, options);

    if (shouldFetchClientCredits) {
      this.fetchClientCredits(formattedPhoneNumber);
    }
  }

  updateState(ns: Partial<EventLogExtendedState>, options: SetStateOptions) {
    let newState = { ...this.state, ...ns };
    const oldState = this.state;

    if (_.isEqual(this.state, newState)) {
      return;
    }

    if (
      ns.mode === EventLogMode.CREDIT &&
      oldState.mode === EventLogMode.PBX &&
      !options.modeOverride
    ) {
      newState.mode = EventLogMode.PBX;
    }

    if (ns.call && (!this.state.call || ns.call.id !== this.state.call.id)) {
      newState.call = ns.call;
      newState.phoneNumber = ns.call.phoneNumber;
      newState.subheader =
        !this.state.subheader || this.state.subheader !== EventLogSubheader.PBX
          ? EventLogSubheader.PBX
          : this.state.subheader;
      newState.selectedCredit = options.isInCreditEdit
        ? this.state.openedCredit
        : null;
    } else if (this.state?.call) {
      newState.phoneNumber = this.state.call.phoneNumber;
    } else if (ns.phoneNumber && ns.phoneNumber !== this.state.phoneNumber) {
      newState.phoneNumber = ns.phoneNumber;
      this.shouldFetchCredits = true;

      if (
        ns.phoneNumber === HIDDEN_PHONE_NUMBER ||
        ns.phoneNumber === INQUIRY_PHONE_NUMBER
      ) {
        this.shouldFetchCredits = false;
        newState.historyType =
          this.state.openedCredit ||
          this.state.historyType === EventLogHistoryType.CREDIT
            ? EventLogHistoryType.CREDIT
            : null;
      }

      if (
        (ns?.phoneNumber !== HIDDEN_PHONE_NUMBER &&
          ns?.phoneNumber !== INQUIRY_PHONE_NUMBER &&
          this.state.phoneNumber === HIDDEN_PHONE_NUMBER) ||
        (this.state.phoneNumber === INQUIRY_PHONE_NUMBER &&
          !this.state.historyType)
      ) {
        newState.historyType = EventLogHistoryType.PHONE;
      }
    }

    if (
      (ns.phoneNumber === HIDDEN_PHONE_NUMBER ||
        ns.phoneNumber === INQUIRY_PHONE_NUMBER) &&
      ns.call &&
      this.state.openedCredit
    ) {
      newState.historyType = EventLogHistoryType.CREDIT;
    }

    this.state = newState;

    this.propagateState();
    this.handleRightDrawerAction(options);
  }

  // ns - New state
  // os - Old state
  shouldFetchClientCredits(
    ns: Partial<EventLogExtendedState>,
    os: Partial<EventLogExtendedState>,
    isFromCreditEdit: boolean
  ) {
    const phoneNumberChanged =
      ns?.phoneNumber &&
      ns?.phoneNumber !== os?.phoneNumber &&
      !ns?.call &&
      !os?.call &&
      (!os?.selectedCredit ||
        os?.openedCredit?.id !== os?.selectedCredit?.id ||
        (ns.openedCredit && ns?.openedCredit?.id !== os?.selectedCredit?.id));
    const callChanged =
      (ns?.call &&
        os?.call &&
        ns?.call?.phoneNumber !== os?.call?.phoneNumber) ||
      (ns?.call && !os?.call && !isFromCreditEdit);

    return phoneNumberChanged || callChanged;
  }

  fetchClientCredits(phoneNumber: string) {
    if (
      phoneNumber === HIDDEN_PHONE_NUMBER ||
      phoneNumber === FORMATTED_HIDDEN_PHONE_NUMBER ||
      phoneNumber === FORMATTED_INQUIRY_PHONE_NUMBER
    ) {
      this.isFetchingCredits = false;
      this.state = {
        ...this.state,
        clientFoundByMobile: null,
        selectedCredit: null,
        historyType: this.state.openedCredit
          ? EventLogHistoryType.CREDIT
          : null,
        creditContextArr: [],
      };
      this.propagateState();

      return;
    }

    if (this.state.mode === EventLogMode.CREDIT) {
      this.state.creditContextArr = [this.state.openedCredit];
      this.state.selectedCredit = this.state.openedCredit;
    } else if (this.state.mode === EventLogMode.CLIENT) {
      this.state.selectedCredit = null;
    } else {
      this.fetchCredits(phoneNumber);
      // this.fetchClient(phoneNumber);
    }

    this.propagateState();
  }

  fetchClient(phoneNumber: string) {
    this.clientService.getClientByMobile(phoneNumber).subscribe({
      next: (res: Page<Client>) => {
        if (res.content?.length === 0) {
          this.state.clientFoundByMobile = null;
          this.notifications.showLocalizedErrorMessage({
            notificationText: `Не е намерен клиент по телефонен номер ${phoneNumber}.`,
          });
          return;
        }
        const activeClients = res.content
          .filter(
            (client: Client) =>
              client.status && client.status.name === ClientStatusNames.ACTIVE
          )
          .sort((a: Client, b: Client) => b.id - a.id);

        if (activeClients.length > 0) {
          this.state.clientFoundByMobile = activeClients[0];
        } else {
          const allClientsSorted = res.content.sort(
            (a: Client, b: Client) => b.id - a.id
          );

          this.state.clientFoundByMobile = allClientsSorted[0];
        }

        this.propagateState();

        window.open(
          `/#/clients/${this.state.clientFoundByMobile.id}`,
          '_blank'
        );
      },
      error: (error: HttpErrorResponse) => {
        this.notifications.showLocalizedErrorMessage({
          notificationText: `Не е намерен клиент по телефонен номер ${phoneNumber}.`,
        });
      },
    });
  }

  fetchCredits(phoneNumber: string) {
    this.shouldFetchCredits = false;
    const currentCreditsRequestId = ++this.creditsRequestId;

    this.isFetchingCredits = true;
    this.creditService
      .searchCredit({ phone: phoneNumber })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          this.isFetchingCredits = false;

          return of([]);
        })
      )
      .subscribe((credits: Credit[]) => {
        if (currentCreditsRequestId === this.creditsRequestId) {
          this.state.creditContextArr = credits;
          this.isFetchingCredits = false;
          if (
            this.state.openedCredit &&
            !credits?.some(
              (credit: Credit) => credit.id === this.state.openedCredit.id
            )
          ) {
            if (!this.state.selectedCredit && !this.state.call) {
              this.state.creditContextArr.unshift(this.state.openedCredit);
              this.state.selectedCredit = this.state.openedCredit;
            }
          }
          this.propagateState();
        }
      });
  }

  propagateState() {
    this.state$.next(this.state);
  }

  handleRightDrawerAction(options: SetStateOptions) {
    const { openDrawer, closeDrawer } = options;

    if (openDrawer && !this.rightDrawer.drawer.opened) {
      this.rightDrawer.open();
    }

    if (closeDrawer && this.rightDrawer.drawer.opened) {
      this.rightDrawer.close();
    }
  }

  getBulgarianPhoneNumberWithCountryCode(phoneNumber: string) {
    if (phoneNumber) {
      return phoneNumber.startsWith('0')
        ? `+359${phoneNumber.substring(1)}`
        : phoneNumber;
    }
  }

  getCurrentCall(): Observable<Call> {
    return this.state$.pipe(
      map((state) => state.call),
      distinctUntilChanged()
    );
  }

  initialState(): EventLogExtendedState {
    return {
      mode: null,
      subheader: null,
      historyType: null,
      openedCredit: null,
      selectedCredit: null,
      client: null,
      clientFoundByMobile: null,
      creditContextArr: null,
      phoneNumber: '',
      call: null,
      failedCallData: null,
    };
  }

  exitEditComponent(
    editComponentType: FilterGroupType.CREDIT | FilterGroupType.CLIENT
  ) {
    this.getCurrentCall()
      .pipe(take(1))
      .subscribe((call) => {
        const options = { closeDrawer: !this.state.call ? true : false };

        if (!call) {
          this.resetState();
        } else {
          if (editComponentType === FilterGroupType.CREDIT) {
            this.setState(
              {
                openedCredit: null,
                mode: this.state.call ? EventLogMode.PBX : null,
                historyType: this.state.call ? EventLogHistoryType.PHONE : null,
              },
              { ...options, isInCreditEdit: false }
            );
          } else if (editComponentType === FilterGroupType.CLIENT) {
            this.setState(
              {
                client: null,
                mode: this.state.call ? EventLogMode.PBX : null,
                historyType: this.state.call ? EventLogHistoryType.PHONE : null,
              },
              { ...options, isInClientEdit: false }
            );
          }
        }

        this.handleRightDrawerAction(options);
      });
  }

  resetState() {
    const initialState = this.initialState();
    this.state = initialState;
    this.propagateState();
  }

  toggleLogButton() {
    this.logButtonVisible$.next(!this.logButtonVisible$.value);
  }

  isLogButtonVisible(): Observable<boolean> {
    return this.logButtonVisible$;
  }
}
