<form
  novalidate
  [formGroup]="idCardListDataForm"
  flex
  layout="row"
  layout-wrap
  layout-align="space-between center"
>
  <div
    flex
    layout="row"
    layout-wrap
    layout-align="space-between start"
  >
    <mat-form-field flex="100" class="margin-top-20">
      <div layout="row"
           layout-wrap
           layout-align="space-between center"
           flex="100">
        <input
          matInput
          readonly
          formControlName="newFile"
          placeholder="{{(idCardImageType === IdCardImageTypes.ID_FRONT ? 'mi.idCardFront' : idCardImageType === IdCardImageTypes.ID_BACK ? 'mi.idCardBack' : 'mi.idCardSelfie') | translate }}"
          [value]="idCardListDataForm?.controls['newFile'].value.name || ''"
          #fileResult
          (click)="fileUpload.triggerUploadButton()"
          flex
        />
        <itfg-file-upload
        matTooltip="{{ 'global.save' | translate }}"
        matTooltipPosition="above"
        #fileUpload
        (fileSelected)="onFileSelected($event, idCardListDataForm?.controls['newFile'])"></itfg-file-upload>
        <button [disabled]="idCardListDataForm?.controls['newFile'].value === '' || idCardListDataForm?.controls['newFile'].errors"
                mat-icon-button
                class="mat-elevation-z2"
                matTooltip="{{ 'global.save' | translate }}"
                matTooltipPosition="above"
                [ngClass]="{ 'save-changes': idCardListDataForm?.controls['newFile'].value !== '' && !idCardListDataForm?.controls['newFile'].errors }"
                (click)="saveDocument($event)"
        >
          <mat-icon>save</mat-icon>
        </button>

      </div>
      <mat-error *ngIf="idCardListDataForm.get('newFile').errors?.maxSize"
                 [translate]="'forms.maxSize'"
      [translateParams]="{value: maxFileSize}">
        Required field
      </mat-error>

    </mat-form-field>
  </div>
</form>
<div *ngIf="idCardListData?.length > 0">
  <div
    *ngFor="let document of idCardListData"
    flex="100"
    layout="row"
    layout-align="space-between center"
  >
    <div
      flex="100"
      layout="row"
    >
      <mat-form-field flex="100">
        <div flex="100" layout="row">
          <input readonly
                matTooltip="{{document.name}}"
                matTooltipPosition="above"
                 matInput
                 [ngStyle]="{ 'font-weight' : selectedImageId === document.id ? 'bold' : 'normal'}"
                 type="text"
                 (click)="changeImage(document)"
                 [value]="document.name | shortenFileName: 30"/>
          <div matSuffix layout="row">
            <button
              *ngIf="selectedImageId === document.id"
              type="button"
              mat-icon-button
              class="mat-elevation-z2"
              (click)="rotateImage(document, false)"
            >
              <mat-icon>rotate_left</mat-icon>
            </button>
            <button
              *ngIf="selectedImageId === document.id"
              type="button"
              mat-icon-button
              class="mat-elevation-z2"
              (click)="rotateImage(document, true)"
            >
              <mat-icon>rotate_right</mat-icon>
            </button>
            <button
              type="button"
              mat-icon-button
              class="mat-elevation-z2"
              (click)="changeImage(document)"
            >
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button
              type="button"
              mat-icon-button
              class="mat-elevation-z2"
              (click)="deleteImage(document)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

      </mat-form-field>
    </div>
  </div>
</div>

