import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Client } from '../../../core/types/client';
import { IdentityReportsService } from '../../../core/services/identity-reports.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  ListElement,
  ClientMapperTypes,
  ClientMIMapperTypes,
  MIReportData,
  IdentityReport,
  DataForeignCitizen, IdCardImageTypes, IdCardImage
} from '../../../core/types/identity-reports';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { NotificationService } from '../../../core/services/notification.service';
import { Utils } from '../../../core/utils/utils';
import { ClientService } from '../../../core/services/client.service';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil, finalize, filter, mergeMap, catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IdentityDocument } from '../../../core/types/client-document';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../../core/services/error.service';
import { FaceRecognitionService } from '../../../core/services/face-recongition.service';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { BrandCodes } from '@app/core/types/brand';
import {VerificationStatusEditComponent} from '@app/main/client/client-edit/verification-status-edit/verification-status-edit.component';
import {VERIFICATION_STATUS_STYLE_MAP, VerificationStatusType} from '@core/verification-status-type';

@Component({
  selector: 'itfg-mi-report',
  templateUrl: './mi-report.component.html',
  styleUrls: ['./mi-report.component.scss']
})
export class MiReportComponent implements OnInit, OnChanges, OnDestroy {

  @Input() client: Client;

  @ViewChild('idCardImagesElement') idCardImagesEl: ElementRef;
  @ViewChild('clientMapperElement') clientMapperEl: ElementRef;
  @ViewChild('updateClientPanel') updateClientPanel: MatExpansionPanel;
  @ViewChild('updateClientPanel', { read: ElementRef }) updateClientPanelElement: ElementRef;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  public reportData: IdentityReport<MIReportData>;
  faceVerification: any;
  public clientReportData: MIReportData;
  public dataForeignCitizen: DataForeignCitizen;
  public isReport = false;
  public isListData = false;
  public isClientDataMatch = false;
  public IdCardImageTypes: typeof IdCardImageTypes = IdCardImageTypes;
  public idCardFront: IdCardImage;
  public idCardFrontList: IdentityDocument[];
  public idCardBack: IdCardImage;
  public idCardBackList: IdentityDocument[];
  idCardSelfie: IdCardImage;
  public idCardSelfieList: IdentityDocument[];
  verificationTypes: typeof VerificationStatusType = VerificationStatusType;
  VERIFICATION_STATUS_STYLE_MAP: typeof VERIFICATION_STATUS_STYLE_MAP = VERIFICATION_STATUS_STYLE_MAP;
  highlightIdCardFrontImage = false;


  _unsubscribe: Subject<void> = new Subject<void>();

  public miListData: ListElement[];
  public requestTime: Date;

  public dialogConfig: any;
  public UNAVAILABLE_IMAGE: string;
  faceVerificationLoading = false;

  public CLIENT_MAPPER: { [key: string]: string };

  constructor(private identityReportsService: IdentityReportsService,
    private clientService: ClientService,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private _viewContainerRef: ViewContainerRef,
    private dialogService: DialogProviderService,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private errorService: ErrorService,
    private faceRecognition: FaceRecognitionService) {

    this.CLIENT_MAPPER = {
      [ClientMapperTypes.ID_CARD_NUMBER]: ClientMIMapperTypes.IDENTITY_DOCUMENT_NUMBER,
      [ClientMapperTypes.ID_CARD_ISSUED_DATE]: ClientMIMapperTypes.ISSUE_DATE,
      [ClientMapperTypes.ID_CARD_ISSUER_NAME]: ClientMIMapperTypes.ISSUER_NAME,
      [ClientMapperTypes.FIRST_NAME]: ClientMIMapperTypes.PERSONAL_NAMES_FIRST_NAME,
      [ClientMapperTypes.MIDDLE_NAME]: ClientMIMapperTypes.PERSONAL_NAMES_SURNAME,
      [ClientMapperTypes.LAST_NAME]: ClientMIMapperTypes.PERSONAL_NAMES_FAMILY_NAME,
      [ClientMapperTypes.ID_CARD_ADDRESS_CITY_NAME]: ClientMIMapperTypes.PERMANENT_ADDRESS_SETTLEMENT_NAME,
      [ClientMapperTypes.ID_CARD_ADDRESS_ADDRESS]: ClientMIMapperTypes.ADDRESS,
      [ClientMapperTypes.NATIONALITY_NAME]: ClientMIMapperTypes.NATIONALITY_NAME,
      [ClientMapperTypes.SECOND_NATIONALITY_NAME]: ClientMIMapperTypes.SECOND_NATIONALITY_NAME,
    };
    this.UNAVAILABLE_IMAGE = 'assets/images/unavailable-image.jpg';
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.clientService.getClientById(Number(params.get('id')))
        )
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        client => {
          this.client = client;
          if (this.client?.id) {
            this.setInitialGridData();
          }
        },
        error => {
          this.errorService.handleError(error, this.route.data);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.client?.id) {
      this.setInitialGridData();
    }
  }

  createMiForm() {
    return this.formBuilder.group({
      miList: [''],
    });
  }

  setInitialGridData() {

    this.clientReportData = {};
    this.dataForeignCitizen = {};
    this.reportData = null;
    this.faceVerification = null;

    this.getIdCardImages(this.client.civilId, <BrandCodes>this.client.brand.code);

    this.identityReportsService.getMiReportsList(this.client.civilId).subscribe((miListData) => {

      this.miListData = miListData;

      if (miListData.length > 0) {
        this.isListData = true;

        for (const listData of miListData) {
          const time = new Date(listData.time);
          listData.time = time;
        }
        const reportId = this.miListData[0].id;

        this.isListData = true;
        this.getMiById(reportId);
      } else {
        this.isListData = false;
        this.isReport = false;
      }

    });
  }

  setImage(clientCivilId: string, file) {
    if (file && file.id) {
      this.identityReportsService
        .renderIdentityDocument(clientCivilId, file.id)
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(doc => {
          const blob = new Blob(<any>[doc], { type: 'image/jpg' });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          const url = window.URL.createObjectURL(blob);
          reader.onload = () => {
            if (file.name.startsWith('ID_FRONT')) {
              this.idCardFront.image = reader.result;
              this.idCardFront.name = file.name;
              this.idCardFront.id = file.id;
              this.idCardFront.url = url;
              this.verifyUserIdentity();
            } else if (file.name.startsWith('ID_BACK')) {
              this.idCardBack.image = reader.result;
              this.idCardBack.name = file.name;
              this.idCardBack.id = file.id;
              this.idCardBack.url = url;
            } else {
              this.idCardSelfie.image = reader.result;
              this.idCardSelfie.name = file.name;
              this.idCardSelfie.id = file.id;
              this.idCardSelfie.url = url;
            }
          };
        }, error => {
          if (error.error instanceof Blob && error.error.type === 'application/json') {
            if (file.name.startsWith('ID_FRONT')) {
              this.idCardFront.image = this.UNAVAILABLE_IMAGE;
              this.idCardFront.name = file.name;
              this.idCardFront.id = file.id;
              this.idCardFront.url = this.UNAVAILABLE_IMAGE;
              this.faceVerification = null;
            } else if (file.name.startsWith('ID_BACK')) {
              this.idCardBack.image = this.UNAVAILABLE_IMAGE;
              this.idCardBack.name = file.name;
              this.idCardBack.id = file.id;
              this.idCardBack.url = this.UNAVAILABLE_IMAGE;
            } else {
              this.idCardSelfie.image = this.UNAVAILABLE_IMAGE;
              this.idCardSelfie.name = file.name;
              this.idCardSelfie.id = file.id;
              this.idCardSelfie.url = this.UNAVAILABLE_IMAGE;
            }
          }
        });
    }
  }


  miDataChanged(event) {
    const reportId = event.value.id;
    this.getMiById(reportId);
  }
  editVerificationStatus(verificationType, status) {
    console.log(verificationType);
    const verificationStatuses$ = this.clientService.getVerificationStatuses$(verificationType);
    verificationStatuses$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(statusList => {
        this.dialogService
          .open(VerificationStatusEditComponent, {
            data: {
              type: verificationType,
              civilId: this.client?.civilId,
              brandCode: this.client?.brand?.code,
              status: status,
              verificationStatusList: statusList,
            },
            minWidth:'350px'
          })
          .afterClosed()
          .pipe(filter(success => !!success))
          .subscribe((success: boolean) => {
            console.log(success);
          });
      });
  }

  getNewMi() {
    this.translate
      .get([
        'mi.getNewMiReportWarning',
        'mi.miReportCriteriaMissing',
        'global.confirm',
        'global.cancel',
        'mi.getNewMiReport',
      ])
      .subscribe(translation => {
        this.dialogConfig = {
          message: translation['mi.getNewMiReportWarning'],
          disableClose: false,
          viewContainerRef: this._viewContainerRef,
          title: translation['mi.getNewMiReport'],
          cancelButton: translation['global.cancel'],
          acceptButton: translation['global.confirm'],
        };

        this.dialogService
          .openConfirm(this.dialogConfig)
          .afterClosed()
          .subscribe((accept: boolean) => {
            if (accept) {
              if (this.client.civilId && this.client.idCardNumber) {
                this.identityReportsService
                  .newMiReport(this.client.civilId, this.client.idCardNumber)
                  .subscribe(mvrReport => {
                    this.reportData = mvrReport;
                    this.requestTime = new Date(this.reportData.time);
                    this.verifyUserIdentity();
                    this.clientReportData = this.reportData.data;
                    this.isReport = true;
                    this.identityReportsService.getMiReportsList(this.client.civilId).subscribe((miListData) => {
                      this.miListData = miListData;

                      if (miListData.length > 0) {
                        this.isListData = true;
                        for (const listData of miListData) {
                          const time = new Date(listData.time);
                          listData.time = time;
                        }
                      }
                    });
                  });
              } else {
                this.notificationService.showLocalizedSnackbarNotification({
                  notificationText: translation['mi.miReportCriteriaMissing'],
                  action: 'error',
                });
              }

            }
          });
      });
  }

  getMiById(reportId) {
    this.identityReportsService.getMIReportById(this.client.civilId, reportId)
      .subscribe(mvrReport => {
        this.reportData = mvrReport;
        this.requestTime = new Date(this.reportData.time);

        this.clientReportData = this.reportData.data;
        this.isReport = true;
      });
  }

  idCardImageChange(document: IdentityDocument) {
    this.setImage(this.client.civilId, document);
    Utils.scrollElementToTop(this.idCardImagesEl.nativeElement);
  }

  idCardImageDelete(document: IdentityDocument) {
    this.identityReportsService.deleteIdentityDocument(this.client.civilId, document.id)
      .subscribe(translation => {
        this.getIdCardImages(this.client.civilId, <BrandCodes>this.client.brand.code);
        this.notificationService.showLocalizedSuccessMessage({
          notificationText: 'mi.idCardImageDeleteSuccessMessage',
        });
      });
  }

  idCardImageSave(data) {
    const formData: FormData[] = [];
    formData.push(new FormData());
    formData[0].append('file', data.document);

    this.identityReportsService
      .addIdentityDocument$(this.client.civilId, <BrandCodes>this.client.brand.code, data.documentType, formData[0])
      .subscribe({
        next: () => {
          this.getIdCardImages(this.client.civilId, <BrandCodes>this.client.brand.code);
          this.notificationService.showLocalizedSuccessMessage({
            notificationText: 'mi.idCardImageUploadSuccessMessage',
          });
        },
        error: (err) => {
          this.errorService.showSnackbarErrorMessage(err);
        }
      });
  }


  getIdCardImages(clientCivilId: string, clientBrandCode: BrandCodes) {
    this.idCardFront = {
      image: this.UNAVAILABLE_IMAGE,
      name: '',
      id: null,
      url: this.UNAVAILABLE_IMAGE
    };

    this.idCardBack = {
      image: this.UNAVAILABLE_IMAGE,
      name: '',
      id: null,
      url: this.UNAVAILABLE_IMAGE
    };
    this.idCardSelfie = {
      image: this.UNAVAILABLE_IMAGE,
      name: '',
      id: null,
      url: this.UNAVAILABLE_IMAGE
    };
    this.identityReportsService.getIdentityDocumentList$(clientCivilId, clientBrandCode).subscribe(documents => {
      // this.clientService.onDataChange.next(
      //   documents
      // );
      this.identityReportsService.onIdentityDocumentChange.next(documents);
      this.idCardFrontList = documents.filter(document => document.type.name === IdCardImageTypes.ID_FRONT);
      this.idCardBackList = documents.filter(document => document.type.name === IdCardImageTypes.ID_BACK);
      this.idCardSelfieList = documents.filter(document => document.type.name === IdCardImageTypes.SELFIE);
      this.setImage(this.client.civilId, this.idCardFrontList[0]);
      this.setImage(this.client.civilId, this.idCardBackList[0]);
      this.setImage(this.client.civilId, this.idCardSelfieList[0]);
    });
  }

  verifyUserIdentity() {
    // Temporary disabled
    return null;
    this.faceVerification = null;
    // if (this.idCardFront.name = this.UNAVAILABLE_IMAGE) {
    //   return;
    // }
    this.faceVerificationLoading = true;
    this.faceRecognition
      .getUserVerification(this.client.civilId, this.client.id, this.idCardFront.id)
      .subscribe(result => {
        if (!result && this.clientReportData) {
          this.faceRecognition.verifyUserIdentity(this.client.civilId, this.client.id, this.idCardFront.id)
            .pipe(
              catchError(error => {
                console.log(error)
                this.faceVerificationLoading = false;
                return null
              }),
              finalize(() => {
                this.faceVerificationLoading = false;
              })
            )
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(res => {
              this.faceVerification = res;
            }, error => {
              this.faceVerificationLoading = false;
              // this.notificationService.showLocalizedErrorMessage({
              //   notificationText: error.message
              // });
            });
        } else {
          this.faceVerification = result;
          this.faceVerificationLoading = false;
        }
      }, error => {
        this.faceVerificationLoading = false;
        // this.notificationService.showLocalizedErrorMessage({
        //   notificationText: error.message
        // });
      });
  }

  clientDataChanged(event) {
    this.isClientDataMatch = event;
  }

  scrollToPanel(panel: MatExpansionPanel) {
    const panelElement: HTMLElement = panel._body.nativeElement;
    const scrollY = window.scrollY || window.pageYOffset;
    const panelTop = panelElement.getBoundingClientRect().top + scrollY;
    console.log('scroll')

    panel.afterExpand.subscribe(() => {
        Utils.scrollElementToTop(panelElement, 0, 120);
    });
  }

  scrollToClientMapper() {
    if (!this.updateClientPanel.expanded) {
      this.updateClientPanel.open();
      this.scrollToPanel(this.updateClientPanel);
    } else {
      this.updateClientPanelElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}

