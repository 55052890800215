import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenFileName'
})
export class ShortenFileNamePipe implements PipeTransform {

  transform(value: string, maxLength: number = 20): string {
    if (!value || value.length <= maxLength) {
      return value;
    }

    const extensionIndex = value.lastIndexOf('.');
    const extension = extensionIndex !== -1 ? value.substring(extensionIndex) : '';
    const namePart = value.substring(0, extensionIndex !== -1 ? extensionIndex : value.length);

    if (namePart.length + extension.length <= maxLength) {
      return value;
    }

    const visibleChars = maxLength - extension.length - 3;
    const firstPartLength = Math.ceil(visibleChars / 2);
    const lastPartLength = Math.floor(visibleChars / 2);

    const shortenedName = `${namePart.substring(0, firstPartLength)}...${namePart.substring(namePart.length - lastPartLength)}`;
    return `${shortenedName}${extension}`;
  }
}
