<div layout="column">

  @if (listOfDocuments.length > 0) {
  <itfg-data-table #columnTable
                   [data]="listOfDocuments.slice().reverse()"
                   [columns]="documentsColumns"
                   [isClickable]="false"
                   [stickyHeader]="true">
    <ng-template itfgDataTableTemplate="createdAt"
                 let-value="value">
      {{ !!value ? (value | itfgDateFormat) : '-' }}
    </ng-template>
    <ng-template itfgDataTableTemplate="name"
                 let-value="value">
      <span matTooltip="{{value}}"
            matTooltipPosition="above">
        <div layout="row"
             layout-align="start center">
          <mat-icon fontSet="fa-solid"
                    [class]="(value | documentExtensionIcon)?.icon"
                    [style.color]="(value | documentExtensionIcon)?.color"></mat-icon>
          {{ value | shortenFileName: 45 }}
        </div>
      </span>
    </ng-template>
    <ng-template itfgDataTableTemplate="actions"
                 let-value="value"
                 let-row="row"
                 let-column="column">
      <div layout="row"
           flex>
        <a [disabled]="!row.id"
           matSuffix
           mat-icon-button
           matTooltip="{{'global.download'|translate}}"
           matTooltipPosition="above"
           (click)="downloadDocumentClicked($event, row)"
           class="input-suffix-button">
          <mat-icon>file_download</mat-icon>
        </a>
        <button [disabled]="
 ('USER_DOCUMENT_DELETE' | isUnauthorized | async) &&
 document?.id
 "
                type="button"
                mat-icon-button
                (click)="deleteDocumentRow(row)"
                matTooltip="{{'global.delete'|translate}}"
                matTooltipPosition="above">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </ng-template>
  </itfg-data-table>
  } @else {
  <h4 class="text-center">{{ 'users.noUserDocumentsFound' | translate }}</h4>
  }
  <div style="margin: 6px;">
    <button mat-raised-button
            (click)="fileUpload.triggerUploadButton()">
      <mat-icon>add</mat-icon>
    </button>
    <div style="display: none;">
      <itfg-file-upload #fileUpload
                        [hidden]="false"
                        (fileSelected)="onFileSelected($event)"></itfg-file-upload>
    </div>
  </div>
  <!-- <form novalidate
        [formGroup]="documentsDataForm"
        flex
        layout="row"
        layout-wrap
        layout-align="space-between center">
    <div *ngFor="
        let document of listOfDocuments.slice().reverse();
        let i = index
        "
         flex="100"
         layout="row"
         layout-wrap
         layout-align="space-between center">
      <div layout="row"
           layout-align="space-around start"
           class="column-gap-10"
           flex
           (click)="inputControl($event)">
        <mat-form-field (click)="fileUpload.triggerUploadButton()"
                        flex>
          <input matInput
                 readonly
                 [formControlName]="USER_DOCUMENT_TEXT + document.index"
                 placeholder="{{ 'clients.documentOrImage' | translate }}"
                 [value]="documentsDataForm.controls[USER_DOCUMENT_TEXT + document.index]?.value?.name || ''"
                 #fileResult
                 itfgOverflowTooltip
                 matTooltip
                 [tooltipText]="fileResult.value"
                 [tooltipPosition]="'above'" />
          <mat-label>
            @if(document?.type) {
            Лична карта - {{ document?.type }}
            } @else {
            {{ 'clients.documentOrImage' | translate }}
            }
          </mat-label>
          <mat-icon color="primary"
                    matPrefix>{{ document.name | documentExtensionIcon }}</mat-icon>
          <a *ngIf="document && document.id"
             flex
             matSuffix
             mat-icon-button
             (click)="downloadDocumentClicked($event, document)"
             class="input-suffix-button"
             matTooltip="{{'global.download'|translate}}"
             matTooltipPosition="above">
            <mat-icon>file_download</mat-icon>
          </a>
          <mat-hint class="override-color"
                    *ngIf="
                      documentsDataForm.controls[USER_DOCUMENT_TEXT + document.index]
                      .errors &&
                      documentsDataForm.controls[USER_DOCUMENT_TEXT + document.index]
                      .errors.invalidField
                      "
                    [translate]="'clients.fileNameTooLong'"
                    [translateParams]="{ value: MAX_FILE_NAME_LENGTH }">Some of the fields are invalid
          </mat-hint>
          <mat-hint *ngIf="!document?.id"
                    [translate]="'clients.selectDocumentInProperFormat'">selectDocumentInProperFormat
          </mat-hint>
        </mat-form-field>
        <itfg-file-upload #fileUpload
                          [hidden]="true"
                          (fileSelected)="onFileSelected($event, documentsDataForm.controls[USER_DOCUMENT_TEXT + document.index])"></itfg-file-upload>
      </div>
    </div>
  </form> -->


</div>
