import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { GcInstallment, GcSummaryCredit } from '../types/gc-credit-summary';

@Injectable({
  providedIn: 'root',
})
export class GcCreditSummaryService {
  constructor(private request: RequestService) { }

  getSummary$(clientCivilId: string): Observable<GcSummaryCredit[]> {
    return this.request.get(['gc', 'credits', 'summary'], {
      params: { civilId: clientCivilId },
    });
  }

  getInstallments$(creditId: string): Observable<GcInstallment[]> {
    return this.request.get(['gc', 'credits', 'installments'], {
      params: { creditId: creditId },
    });
  }

  toggleForfeit$(creditId: string, installmentNumber: number, forfeitEnabled: boolean,): Observable<GcInstallment[]> {
    return this.request.post(['gc', 'credits', 'installments', creditId, 'forfeit-enabled'], {
      body: { forfeitEnabled, installmentNumber },
    });
  }
}
