import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EventLogService } from '../../../core/services/event-log.service';
import { EventLogStateService } from '@app/main/main-layout/right-drawer/event-log/event-log-state.service';
import { EventLogHistoryType } from '@app/main/main-layout/right-drawer/shared/event-log.enums';
import { RightDrawerService } from '@app/main/main-layout/right-drawer/right-drawer.service';
import { filter, take } from 'rxjs';

@Component({
  selector: 'itfg-search-in-event-log-button',
  templateUrl: './search-in-event-log-button.component.html',
  styleUrls: ['./search-in-event-log-button.component.scss'],
})
export class SearchInEventLogButtonComponent implements OnInit, OnDestroy {
  @Input() control: UntypedFormControl;

  constructor(
    private eventLogService: EventLogService,
    private eventLogStateService: EventLogStateService,
    private rightDrawer: RightDrawerService
  ) {}

  ngOnInit() {}

  searchInEventLog() {
    this.eventLogStateService.setState({
      historyType: EventLogHistoryType.PHONE,
      subheader: null,
    });

    if (this.rightDrawer.drawer.opened) {
      this.eventLogService.searchInEventLog.next(this.control.value);
    } else {
      this.rightDrawer.open();

      this.rightDrawer.drawer.openedChange
        .pipe(
          filter((isOpen) => isOpen),
          take(1)
        )
        .subscribe(() => {
          this.eventLogService.searchInEventLog.next(this.control.value);
        });
    }
  }

  ngOnDestroy(): void {
    this.eventLogService.searchInEventLog.next(null);
  }
}
