import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewContainerRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormArray,
  UntypedFormControl,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { FormComponentData } from '../../../../core/types/form-component-data';
import { ClientService } from '../../../../core/services/client.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { IdentifierValidation } from '../../../../core/validation/identifier-validation';
import { DoCheck } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BankAccount } from '@app/core/types/bank-account';
import { initial } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'itfg-bank-accounts-data',
  templateUrl: './bank-accounts-data.component.html',
  styleUrls: ['./bank-accounts-data.component.scss'],
})
export class BankAccountsDataComponent implements OnInit, OnChanges, DoCheck, OnDestroy {
  @Input() data: any;
  @Input() deletedBankAccountIndex: number;
  @Input() erroredAccounts: any;
  @Input() deletedBankAccount: any;
  @Input() initialBankAccounts: any;

  @Output() dataChanged = new EventEmitter<FormComponentData>();
  @Output() bankAccountEdited = new EventEmitter<any>();
  @Output() bankAccountDeleted = new EventEmitter<any>();
  @Output() deleteDocument = new EventEmitter<any>();

  bankAccountForm: UntypedFormGroup;
  formComponentData: FormComponentData = {} as FormComponentData;
  bankAccountList: Array<UntypedFormGroup>;
  editMode: Boolean;
  dialogConfig: any;
  files: any;
  newBankAccountAdded = false;

  MAX_FILE_NAME_LENGTH = 64;

  actionsForEdit: any;
  indexOfEditedAccount = null;
  bankAccountToEmit: any;
  bankAccountWithUserDocumentToDelete: any;

  isFormDirty: boolean;
  _unsubscribe = new Subject<void>();


  constructor(
    public formBuilder: UntypedFormBuilder,
    public clientService: ClientService,
    public translateService: TranslateService,
    public _dialogService: DialogProviderService,
    public _viewContainerRef: ViewContainerRef,
    public route: ActivatedRoute
  ) {
    this.bankAccountList = [];
    this.actionsForEdit = {};

    if (this.route.snapshot.params.id) {
      this.editMode = true;
    }
  }

  ngOnInit() {
    this.setInitialData();
    this.watchForFormChanges();

    this.clientService.onBankAccoutDataChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(data => {
        this.data = data;
        this.setInitialData();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.editMode = true;
      this.newBankAccountAdded = false;
      this.setInitialData();
    }
    if (changes.hasOwnProperty('deletedBankAccountIndex')) {
      this.bankAccountList.splice(
        changes.deletedBankAccountIndex['currentValue'],
        1
      );
    }

    if (this.initialBankAccounts && this.initialBankAccounts.length > 0) {
      this.initialBankAccounts.forEach(initialAccount => {
        this.addBankRecord();
        const bankAccountLatestControl = this.bankAccountForm.controls['bankAccountList']['controls'].length - 1;
        this.bankAccountForm.controls['bankAccountList']['controls'][bankAccountLatestControl].patchValue(initialAccount);
      });
    }
  }

  createActionsCollection() {
    this.actionsForEdit.addNewAccount = [];
    this.actionsForEdit.changeIban = [];
    this.actionsForEdit.changeDocument = [];
    this.actionsForEdit.deleteCodument = [];
    this.actionsForEdit.deleteAccount = [];
  }

  setInitialData() {
    // TODO more logic here
    if (!this.editMode) {
      if (this.bankAccountList.length === 0) {
        this.bankAccountForm = this.createBankAccountForm(this.editMode);
        this.watchForFormChanges();
      }
    } else {
      this.editMode = true;
      this.bankAccountForm = this.createBankAccountForm(this.editMode);
      this.watchForFormChanges();
    }
  }

  createBankAccountForm(editMode) {
    const fb = this.formBuilder;
    this.bankAccountList = [];

    if (!this.editMode) {
      this.bankAccountList.push(
        new UntypedFormGroup({
          iban: new UntypedFormControl('', [
            IdentifierValidation.isValidIban,
            Validators.required,
          ]),
          userDocument: new UntypedFormControl(''),
          verified: new UntypedFormControl(false),
        })
      );
    } else {
      for (const account of this.data.bankAccounts || []) {
        const currentFormGroup = new UntypedFormGroup({
          iban: new UntypedFormControl(
            { value: account.iban, disabled: true },
            IdentifierValidation.isValidIban
          ),
          userDocument: new UntypedFormControl((account.userDocument?.name ? account.userDocument.name : account.userDocument) || ''),
          verified: new UntypedFormControl({
            value: account.verified,
            disabled: true,
          }),
          id: new UntypedFormControl(account.id),
        });
        //  currentFormGroup.controls['userDocument'].disable();
        this.bankAccountList.push(currentFormGroup);
      }
    }

    const bankAccounts = new UntypedFormArray(this.bankAccountList);

    let bankAccountForm: any;
    bankAccountForm = {};
    bankAccountForm['bankAccountList'] = bankAccounts;

    if (this.editMode) {
      // bankAccountForm['bankAccountList'].disable();
    }

    return fb.group(bankAccountForm);
  }

  validateFormItems(accounts: any) {
    // tslint:disable-next-line:prefer-const
    for (let account of accounts.controls) {
      // tslint:disable-next-line:prefer-const
      for (let documentProperty in account.controls.userDocument.value) {
        if (
          documentProperty === 'name' &&
          account.controls.userDocument.value[documentProperty].length > 5
        ) {
          const currentFileName = account.controls.userDocument.value[
            documentProperty
          ].split('.');

          if (currentFileName[0].length > this.MAX_FILE_NAME_LENGTH) {
            account.controls.userDocument.setErrors({
              invalidField: { invalidField: 'InvalidField' },
            });
          }
        }
      }
    }
  }

  watchForFormChanges() {
    this.bankAccountForm.controls['bankAccountList'].valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        val => {
          this.validateFormItems(
            this.bankAccountForm.controls['bankAccountList']
          );
          // if edit action return
          if (
            this.indexOfEditedAccount !== null &&
            !this.bankAccountForm.controls['bankAccountList'].valid
          ) {
            return;
          }

          this.isFormDirty = false;

          this.dataChanged.emit({
            valid: this.bankAccountForm.valid,
            data: this.parseExistingForms(),
            dirty: this.isFormDirty,
          });
        }
      );
  }

  addBankRecord() {
    this.newBankAccountAdded = true;
    this.isFormDirty = true;

    const newBankAccount = new UntypedFormGroup({
      iban: new UntypedFormControl({ value: '', disabled: false }, [
        IdentifierValidation.isValidIban,
        Validators.required,
      ]),
      userDocument: new UntypedFormControl(''),
      verified: new UntypedFormControl(false),
      id: new UntypedFormControl(null),
    });

    this.bankAccountList.push(newBankAccount);

    const bankAccountLatestControl =
      this.bankAccountForm.controls['bankAccountList']['controls'].length - 1;

    this.bankAccountForm.controls['bankAccountList']['controls'][
      bankAccountLatestControl
    ].valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(val => {
        this.validateFormItems(this.bankAccountForm.controls['bankAccountList']);
        this.isFormDirty = true;
        this.dataChanged.emit({
          valid: this.bankAccountForm.controls['bankAccountList']['controls'][
            bankAccountLatestControl
          ].valid,
          data: this.parseExistingForms(),
          dirty: this.isFormDirty,
        });
      });
  }

  parseExistingForms() {
    const currentAccounts = [];

    // clear existing collections
    this.createActionsCollection();

    for (
      let i = 0;
      i < this.bankAccountForm.controls['bankAccountList']['controls'].length;
      i++
    ) {
      if (this.editMode) {
        // TODO Fix later
        this.compareCurrentDataWithParent(
          this.bankAccountForm.controls['bankAccountList']['controls'][i]
        );

        // CheckForNewItems - if no id , should be a new item
        if (
          !this.bankAccountForm.controls['bankAccountList']['controls'][i].id
        ) {
          this.actionsForEdit.addNewAccount.push(
            this.bankAccountForm.controls['bankAccountList']['controls'][i]
          );
        }
      }

      currentAccounts.push(
        this.bankAccountForm.controls['bankAccountList']['controls'][i].value
      );
    }

    return currentAccounts;
  }

  compareCurrentDataWithParent(currentData: any) {
    const bankAccountFound = false;
    if (this.data.bankAccounts) {
      for (let i = 0; i < this.data.bankAccounts.length; i++) {
        if (currentData.value.id === this.data.bankAccounts[i].id) {
          const currentDocumentDeleted =
            (!currentData.value.hasOwnProperty('userDocument') ||
              currentData.value.userDocument === undefined) &&
            (this.data.bankAccounts[i].hasOwnProperty('userDocument') ||
              (this.data.bankAccounts[i].userDocument !== undefined &&
                this.data.bankAccounts[i].userDocument !== null));

          const currentDocumentAdded =
            currentData.value.hasOwnProperty('userDocument') &&
            currentData.value.userDocument !== '' &&
            (!this.data.bankAccounts[i].hasOwnProperty('userDocument') ||
              this.data.bankAccounts[i].userDocument === undefined ||
              this.data.bankAccounts[i].userDocument === null);

          const currentIbanChanged =
            currentData.value.iban !== this.data.bankAccounts[i].iban;
          const verifiedStatusChanged =
            currentData.value.verified !== this.data.bankAccounts[i].verified;

          let userDocumentChanged = false;
          if (
            currentData.value.userDocument &&
            this.data.bankAccounts[i].userDocument
          ) {
            if (currentData.value.userDocument.lastModified) {
              userDocumentChanged = true;
            }
          }

          // cover potential iban change + new document added
          if (
            (currentDocumentAdded || userDocumentChanged) &&
            this.indexOfEditedAccount === i
          ) {
            this.bankAccountToEmit = currentData.value;
            return;
          }

          // cover potential iban change + document deleted
          if (currentDocumentDeleted) {
            // delete document
            this.bankAccountWithUserDocumentToDelete = this.data.bankAccounts[i];

            // send request for changed iban
            if (currentIbanChanged && this.indexOfEditedAccount === i) {
              const dataToEmit = {
                iban: currentData.value.iban,
                verified: currentData.value.verified,
                id: currentData.value.id,
              };
              this.bankAccountToEmit = dataToEmit;
            }
            return;
          }

          if (currentIbanChanged || verifiedStatusChanged) {
            // cover iban or verified status changed
            const dataToEmit = {
              iban: currentData.value.iban,
              verified: currentData.value.verified,
              id: currentData.value.id,
            };
            this.bankAccountToEmit = dataToEmit;
          }
        }
      }
    }
  }

  trimEmptyChars(index: number, iban: UntypedFormControl, event: any) {
    event.preventDefault();
    const clipBoardData = event.clipboardData.getData('Text');
    iban.setValue(clipBoardData.replace(/ /g, ''));
  }

  deleteBankRecord(index: number, bankAccount: UntypedFormControl) {
    this.translateService
      .get(
        [
          'clients.deleteBankRecordMessage',
          'global.delete',
          'global.cancel',
          'global.confirm',
        ],
        { iban: bankAccount['iban'].value }
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        this.dialogConfig = {
          message: translation['clients.deleteBankRecordMessage'],
          disableClose: false,
          viewContainerRef: this._viewContainerRef,
          title: translation['global.delete'],
          cancelButton: translation['global.cancel'],
          acceptButton: translation['global.confirm'],
        };

        this._dialogService
          .openConfirm(this.dialogConfig)
          .afterClosed()
          .pipe(takeUntil(this._unsubscribe))
          .subscribe((accept: boolean) => {
            if (accept) {
              this.indexOfEditedAccount = null;
              if (bankAccount['id'].value !== null) {
                this.bankAccountDeleted.emit({
                  bankAccount: bankAccount,
                  index: index,
                });
              } else {
                this.bankAccountList.splice(index, 1);
                this.newBankAccountAdded = false;
              }
            }
          });
      });
  }

  onFileSelected(file: File, control: UntypedFormControl) {
    control.patchValue(file);
  }

  ngDoCheck() {
    if (this.erroredAccounts.length > 0) {
      for (let i = 0; i < this.erroredAccounts.length; i++) {
        // tslint:disable-next-line:prefer-const
        for (let formControl of this.bankAccountForm.controls[
          'bankAccountList'
        ]['controls']) {
          if (
            this.erroredAccounts[i] &&
            formControl.value.iban === this.erroredAccounts[i].iban
          ) {
            formControl.setErrors({
              invalidField: { invalidField: 'InvalidField' },
            });
            this.erroredAccounts.splice(i, 1);
            break;
          }
        }
      }
    }
  }

  saveEditedBankAccountRecord(bankAccount: any) {
    this.compareCurrentDataWithParent(bankAccount);
    this.emitBankAccountChange(bankAccount);
  }

  emitBankAccountChange(currBankAccount: UntypedFormGroup) {
    this.indexOfEditedAccount = null;
    if (this.bankAccountToEmit) {
      this.bankAccountEdited.emit(this.bankAccountToEmit);
      // clear previously selected values
      this.bankAccountToEmit = null;
    }
    if (this.bankAccountWithUserDocumentToDelete) {
      this.deleteDocument.emit(this.bankAccountWithUserDocumentToDelete);
      // clear previously selected values G
      this.bankAccountWithUserDocumentToDelete = null;
    }
    currBankAccount.controls['iban'].disable();
    currBankAccount.controls['verified'].disable();
  }

  editBankAccountRecord(index: number, currBankAccount: UntypedFormGroup) {
    // clear previously selected values
    // this.bankAccountForm.get('bankAccountList').disable();
    currBankAccount.enable();
    this.bankAccountToEmit = null;
    this.bankAccountWithUserDocumentToDelete = null;

    this.indexOfEditedAccount = index;
  }

  clearFileInput(index: number, event: any) {
    event.preventDefault();
    event.stopPropagation();
    const formCtrl = (this.bankAccountForm.get('bankAccountList') as FormArray).at(index).get('userDocument') as FormControl;
    formCtrl.setValue(undefined);
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
