<div *ngxPermissionsOnly="'CONVERSATION_READ_ALL'">
  <div
    class="edit-screen-header"
    layout="row"
    layout-wrap
    layout-align="space-between"
  >
    <div
      flex-gt-md="33"
      flex-gt-sm="50"
      flex="50"
      layout="row"
      layout-align-gt-md="space-between end"
      layout-align="space-between end"
    >
      <span class="edit-screen-title" [translate]="'communication.emailList'"
        >Emails</span
      >
    </div>

    <div
      flex-gt-md="33"
      flex-gt-sm="50"
      flex="50"
      layout="row"
      layout-align-gt-md="end end"
      layout-align="end end"
    >
      <div *ngIf="!clientEmailConfirmed" class="add-margin-left">
        <!-- TODO: delete disabled for brand code after implementing MF website -->
        <button
          mat-raised-button
          type="button"
          (click)="sendConfirmationEmail($event)"
          matTooltip="{{ 'communication.emailNotVerifiedMessage' | translate }}"
          [disabled]="
            (!this.client?.email && !this.credit?.user?.email) ||
            this.client?.brand.code === 'MF' ||
              this.credit?.user?.brand.code === 'MF'
          "
          matTooltipShowDelay="500"
          matTooltipPosition="above"
        >
          <mat-icon [ngStyle]="{ color: 'red' }">warning</mat-icon>
          <span [translate]="'communication.sendConfirmation'"
            >Send confirmation</span
          >
        </button>
      </div>
      <!-- <div *ngIf="clientEmailConfirmed"
           class="flex-padding">
        <button mat-raised-button
                [disabled]="['EMAIL_SEND'] | isUnauthorized | async"
                type="button"
                (click)="sendTemporaryPasswordEmail($event)">
          <span [translate]="'communication.sendTemporaryPassword'">Send temporary password</span>
        </button>
      </div> -->
      <!-- <div *ngIf="clientEmailConfirmed">
        <button mat-raised-button
                [disabled]="['SMS_SEND'] | isUnauthorized | async"
                type="button"
                (click)="sendNewEmail($event)">
          <mat-icon>add</mat-icon>
        </button>
      </div> -->

      <mat-slide-toggle
        *ngIf="credit"
        [checked]="this.searchOptions.criteria.key === 'user_id'"
        labelPosition="before"
        [(ngModel)]="includeAllEmails"
        (change)="onEmailModeChange($event)"
      >
        <span [translate]="'communication.showAll'">Show All</span>
      </mat-slide-toggle>
    </div>
  </div>

  <mat-card appearance="outlined">
    <mat-card-content>
      <!-- <ng-template tdLoading="emailListLoading"
                   tdLoadingMode="indeterminate"
                   tdLoadingType="circle"
                   tdLoadingStrategy="overlay"
                   tdLoadingColor="accent"> -->
      <itfg-data-table [data]="emailPage?.content" [columns]="emailColumns">
        <ng-template
          itfgDataTableTemplate="credit.id"
          let-value="value"
          let-row="row"
          let-column="column"
        >
          <div
            [itfgHighlightDataTableRow]="
              value === credit.id && includeAllEmails
            "
          >
            <a
              *ngIf="value"
              target="_blank"
              [routerLink]="['/credits', value]"
              >{{ value }}</a
            >
          </div>
        </ng-template>
        <ng-template itfgDataTableTemplate="sent_at" let-sent_at="value">
          <itfg-date-format [date]="sent_at"></itfg-date-format>
        </ng-template>
        <ng-template itfgDataTableTemplate="template.name" let-t="value">
          {{ t }}
        </ng-template>
        <ng-template itfgDataTableTemplate="actions" let-row="row">
          <div layout="row">
            <!-- <a *ngIf="clientEmailConfirmed"
                 mat-icon-button
                 flex
                 (click)="resendEmail(row.id)">
                <mat-icon matTooltip="{{ 'communication.resendEmail' | translate }}"
                          matTooltipShowDelay="500"
                          matTooltipPosition="above">refresh
                </mat-icon>
              </a> -->
            <a mat-icon-button flex (click)="viewSentEmail(row)">
              <mat-icon
                matTooltip="{{ 'communication.emailPreview' | translate }}"
                matTooltipShowDelay="500"
                matTooltipPosition="above"
                >remove_red_eye
              </mat-icon>
            </a>
          </div>
        </ng-template>
      </itfg-data-table>
      <mat-paginator
        #pagingBarResponsive
        (page)="pageChanged($event)"
        [pageIndex]="emailPage.number"
        [length]="emailPage.totalElements"
        [pageSize]="searchOptions.size"
        [pageSizeOptions]="[20, 30, 100]"
        showFirstLastButtons
      >
      </mat-paginator>
      <!-- </ng-template> -->
    </mat-card-content>
  </mat-card>
</div>
