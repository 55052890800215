<div layout="column">
  <div [ngClass]="!clientData ? 'edit-screen-header' : 'credit-client-edit-header'"
       flex>
    <span *ngIf="!isNewItem && !clientData"
          class="edit-screen-title"
          layout="row"
          layout-align="start"
          flex>
      <itfg-brand-logo [brand]="this.client.brand"></itfg-brand-logo>
      <span flex>{{ client | clientFullName }}</span>
    </span>
    <span *ngIf="isNewItem"
          class="edit-screen-title"
          layout="row"
          flex>
      <span flex>{{ 'clients.createNewClient' | translate }}</span>
    </span>
    <div flex="100"
         layout="row"
         layout-wrap
         layout-align="start">
      <div flex
           layout="row"
           layout-wrap>
        <div>
          <itfg-status-data-edit (statusDataChanged)="statusDataChanged($event)"
                                 [clientId]="client?.id"
                                 [clientStatuses]="clientStatuses"
                                 [statusEditData]="this.client.status"></itfg-status-data-edit>
        </div>

        <itfg-tag-chips *ngxPermissionsOnly="'TAG_READ'"
                        [inputId]="client?.id"
                        [inputTags]="client?.tags"
                        [tagTypeName]="tagTypeNames.CLIENT"></itfg-tag-chips>
      </div>
      <div layout="row"
           layout-align="end center"
           flex="nogrow">
        <a [routerLink]="['/credits/applications', applicationId]"
           target="_blank"
           matTooltip="{{ 'applications.redirectToApplication' | translate }}"
           matTooltipPosition="below">
          <button *ngIf="!client.id && applicationId"
                  mat-icon-button
                  class="margin-right-10px mat-elevation-z2">
            <mat-icon>request_quote </mat-icon>
          </button>
        </a>
        <button mat-icon-button
                class="mat-elevation-z2 margin-right-10px"
                [disabled]="client?.tags?.length >= TAGS_LIMIT"
                *ngxPermissionsOnly="'TAG_WRITE'"
                (click)="openAddClientTag()">
          <mat-icon [style.color]="'gray'"
                    matPrefix
                    matTooltipPosition="below"
                    matTooltip="{{
              (client?.tags?.length < TAGS_LIMIT
                ? 'clients.addClientTag'
                : 'credits.addTagLimitReached'
              ) | translate: { tagsLimit: TAGS_LIMIT }
            }}">
            label
          </mat-icon>
        </button>
        <button *ngIf="!isNewItem"
                mat-icon-button
                class="mat-elevation-z2 margin-right-10px"
                [ngStyle]="{
            color: !client?.loyaltyEnabled
              ? 'grey'
              : loyaltyPointsAvailableNumber >= 0
              ? 'green'
              : 'red'
          }"
                [disabled]="false"
                (click)="openLoyaltyPointsDetails()"
                matTooltip="{{
            (client?.loyaltyEnabled
              ? 'clients.loyaltyEnabled'
              : 'clients.loyaltyDisabled'
            ) | translate
          }}">
          <b>{{ loyaltyPointsAvailableNumber
            }}{{ 'clients.pointsShort' | translate }}</b>
        </button>
        <button mat-raised-button
                [disabled]="!enableSaveButton || !clientDataChangesDetected"
                (click)="save()"
                class="button-padding"
                [ngClass]="{
            'changes-detected': enableSaveButton && clientDataChangesDetected
          }">
          {{ 'global.save' | translate }}
        </button>

        <itfg-client-options-menu [clientId]="client.id"
                                  [isInputClient]="!!clientData"
                                  [sendPasswordBySms]="sendPasswordBySms"
                                  [sendPasswordByEmail]="sendPasswordByEmail"
                                  [data]="clientComponentData.profileData"
                                  [isNewItem]="isNewItem"></itfg-client-options-menu>
      </div>
    </div>
  </div>
  <div class="edit-screen-body"
       layout="row"
       layout-wrap
       layout-align="start stretch">
    <div flex-gt-md="33"
         flex-gt-sm="50"
         flex-gt-xs="100"
         flex
         #profileElement>
      <itfg-profile-data-edit #profileComponent
                              [data]="clientComponentData.profileData"
                              [brandList]="brandData"
                              [client]="client"
                              [isNewItem]="isNewItem"
                              (dataChanged)="profileDataChanged($event)"
                              [lastLogin]="lastLogin"
                              [isDirty]="invalidProfileData">
      </itfg-profile-data-edit>
    </div>
    <div flex-gt-md="33"
         flex-gt-sm="50"
         flex-gt-xs="100"
         flex
         #personalElement>
      <itfg-personal-data-edit #personalComponent
                               [data]="clientComponentData.personalData"
                               [client]="client"
                               [isNewItem]="isNewItem"
                               (dataChanged)="personalDataChanged($event)">
      </itfg-personal-data-edit>
    </div>
    <div flex-gt-md="33"
         flex
         #personalIdElement>
      <itfg-personal-id-data-edit #personalIdComponent
                                  [data]="clientComponentData.idCardDataAndAddress"
                                  [client]="client"
                                  [clientId]="client.id"
                                  (dataChanged)="idCardDataAndAddressChanged($event)">
      </itfg-personal-id-data-edit>
    </div>
    <div flex="100"
         #contactElement>
      <itfg-contact-data-edit #contactComponent
                              [client]="client">
      </itfg-contact-data-edit>
    </div>
    <div flex="100"
         #occupationStatusElement>
      <itfg-occupation-status-edit #occupationStatusComponent
                                   [data]="clientComponentData.occupationStatus"
                                   [isNewItem]="isNewItem"
                                   (dataChanged)="occupationStatusDataChanged($event)">
      </itfg-occupation-status-edit>
    </div>
    <div flex-gt-md="50"
         flex-gt-sm="50"
         flex="100"
         *ngxPermissionsOnly="'USER_BANK_ACCOUNT_READ'">
      <itfg-bank-accounts-data [data]="clientComponentData.bankAccounts"
                               [initialBankAccounts]="initialBankAccounts"
                               (dataChanged)="bankAccountsDataChanged($event)"
                               [erroredAccounts]="erroredBankAccounts"
                               (bankAccountEdited)="editBankAccount($event)"
                               (bankAccountDeleted)="deleteBankAccount($event)"
                               (deleteDocument)="deleteBankAccountDocument($event)"
                               [deletedBankAccountIndex]="deletedBankAccountIndex">
      </itfg-bank-accounts-data>
    </div>
    <div flex-gt-md="50"
         flex-gt-sm="50"
         flex="100"
         *ngxPermissionsOnly="'USER_DOCUMENT_READ'">
      <mat-card>
        <mat-card-content>
          <mat-tab-group (selectedTabChange)="onTabChange($event)">
            <mat-tab>
              <ng-template mat-tab-label>
              <div>Документи на клиента</div>
              </ng-template>
              <div>
                <itfg-document-edit [data]="clientComponentData.documents"
                                    [client]="client"
                                    (dataChanged)="documentDataChanged($event)"
                                    (deleteClientDocument)="deleteClientDocument($event)">
                </itfg-document-edit>
              </div>
            </mat-tab>
            <mat-tab label="Снимки за самоличност"
                     [disabled]="!clientComponentData?.identityDocuments || clientComponentData?.identityDocuments.length === 0">
              <div>
                <itfg-data-table #columnTable
                                 [data]="clientComponentData.identityDocuments"
                                 [columns]="identityDocumentsColumns"
                                 [isClickable]="false">
                  <ng-template itfgDataTableTemplate="createdAt"
                               let-value="value">
                    {{ value | itfgDateFormat }}
                  </ng-template>
                  <ng-template itfgDataTableTemplate="type.name"
                               let-value="value">
                    {{'reports.identityVerification.documentTypeShort.' + value | translate}}
                  </ng-template>
                  <ng-template itfgDataTableTemplate="uploaderInfo"
                               let-value="value">
                    {{ (value && value.type) == 'OPERATOR' ? value.email : ( 'identityDocuments.uploaderInfo.type.' +
                    (value?.type || 'SYSTEM') ) | translate }}
                  </ng-template>
                  <ng-template itfgDataTableTemplate="name"
                               let-value="value">
                    <span matTooltip="{{value}}"
                          matTooltipPosition="above">
                      {{ value | shortenFileName: 30 }}
                    </span>
                  </ng-template>
                  <ng-template itfgDataTableTemplate="actions"
                               let-value="value"
                               let-row="row"
                               let-column="column">
                    <div>
                      <a flex
                         matSuffix
                         mat-icon-button
                         matTooltip="{{'global.download'|translate}}"
                         matTooltipPosition="above"
                         (click)="downloadIdentityDocumentClicked($event, row)"
                         class="input-suffix-button">
                        <mat-icon>file_download</mat-icon>
                      </a>
                    </div>
                  </ng-template>
                </itfg-data-table>
              </div>

            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>


    </div>
    <div layout="row"
         layout-align="end center"
         flex
         class="padding-bottom">
      <button mat-raised-button
              [disabled]="!enableSaveButton || !clientDataChangesDetected"
              (click)="save()"
              [ngClass]="{ 'changes-detected': clientDataChangesDetected }">
        {{ 'global.save' | translate }}
      </button>
    </div>
  </div>
</div>
