import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateFormat } from '../../core/types/date-format';
import * as moment from 'moment';

@Pipe({
  name: 'itfgDateFormat',
})
export class ItfgDateFormatPipe extends DatePipe implements PipeTransform {
  transform(
    value: any,
    format: string = DateFormat.DATE_TIME,
    timezone?: string,
    locale?: string
  ): any {

    const toDateTime = (value) => {
      const date = super.transform(value, DateFormat.DATE, timezone, locale);
      const time = super.transform(value, DateFormat.TIME, timezone, locale);
      return [date, time].filter(e => e !== '' && e !== null).join(', ');
    }

    if (format === DateFormat.DATE_TIME) {
      return toDateTime(value);
    }

    if (format === DateFormat.FROM_NOW) {
      let date: moment.Moment = moment(value);
      if (date.isAfter(moment().subtract(60, 'minutes'))) {
        return moment(value).fromNow()
      }
      return toDateTime(value);
    }

    return super.transform(value, DateFormat.DATE, timezone, locale);
  }
}
