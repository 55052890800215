import { Pipe, PipeTransform } from '@angular/core';
import { IbanUtilService } from '../../../core/services/iban-util.service';

@Pipe({
  name: 'documentExtensionIcon'
})
export class UserDocumentIconPipe implements PipeTransform {

  constructor(
  ){}

  transform(name: string): { icon: string, color: string } {
    const imgFormats = ['.jpg', '.jpeg', '.png', '.gif'];

    if (name.endsWith('.pdf')) {
      return { icon: 'fa-file-pdf', color: '#D32F2F' };
    }

    if (imgFormats.some(f => name.endsWith(f))) {
      return { icon: 'fa-file-image', color: '#ff7800' };
    }

    if (name.endsWith('.docx') || name.endsWith('.doc')) {
      return { icon: 'fa-file-word', color: '#1976D2' };
    }

    if (name.endsWith('.xlsx') || name.endsWith('.xls')) {
      return { icon: 'fa-file-excel', color: '#388E3C' };
    }

    if (name.endsWith('.txt')) {
      return { icon: 'fa-file-lines', color: '#757575' };
    }

    return { icon: 'fa-file', color: '#9E9E9E' };

  }
}
