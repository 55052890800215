<button
  tabindex="-1"
  matSuffix
  type="button"
  mat-icon-button
  [disabled]="control.value === '' || control.value === '+359'"
  matTooltip="{{ 'historyLog.searchInEventLogLabel' | translate }}"
  (click)="searchInEventLog()"
>
  <mat-icon>search</mat-icon>
</button>
