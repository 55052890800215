import { NAVIGATION_CONFIG } from './navigation-config';
import { environment } from './../../environments/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { SearchOptionsDTO } from '@app/core/types/search-options';

export enum BrowserStorageType {
  LocalStorage,
  SessionStorage,
}

export interface AuthConfig {
  STORAGE_TYPE: BrowserStorageType;
  SESSION_TOKEN_NAME: string;
}

export interface ApiConnectionConfig {
  ADMIN: string;
  COLLECTION: string;
  IDENTITY_REPORTS: string;
  PAYMENTS: string;
  SCORING: string;
  FACE_RECOGNITION: string;
  CLIENT_COMMUNICATION: string;
  P2P: string;
}

export interface ConnectionConfig {
  BASE: string;
  API: ApiConnectionConfig;
  STATIC: string;
}

export interface FilteringConfig {
  STORAGE_TYPE: BrowserStorageType;
  MULTI_VALUE_SEPARATOR: string;
}

// export interface HtmlEditorConfig {
//   editable: boolean;
//   spellcheck: boolean;
//   height: string;
//   minHeight: string;
//   width: string;
//   minWidth: string;
//   translate: string;
//   enableToolbar: boolean;
//   showToolbar: boolean;
//   placeholder: string;
//   imageEndPoint: string;
//   toolbar: string[][];
// }

export interface AppConfig {
  AUTH: AuthConfig;
  CONNECTION: ConnectionConfig;
  FILTERING: FilteringConfig;
  HTML_EDITOR: AngularEditorConfig;
  ACCESS_DENIED_ROUTE: string;
  LAST_VISITED_ROUTE: string;
  NAVIGATION?: NavigationConfig;
}

export interface BrandConfig {
  [brandCode: string]: {
    name: string;
    logo: string;
  };
}

export class NavigationConfig {
  routes: MenuItem[];
}

export class MenuItem {
  key?: string;
  icon?: string;
  label?: string;
  children?: MenuItem[];
  routerLink?: string;
  permissions?: string | Array<string>; // PermissionType
}

export const BRAND_CONFIG: BrandConfig = {
  SC: {
    name: 'Smile Credit',
    logo: 'assets/images/smile-credit-logo.png',
  },
  GC: {
    name: 'Get Cash',
    logo: 'assets/images/get-cash-logo.png',
  },
  MF: {
    name: 'Medifin',
    logo: 'assets/images/medifin-logo.png',
  },
};

export const APP_CONFIG: AppConfig = {
  AUTH: {
    STORAGE_TYPE: BrowserStorageType.LocalStorage,
    SESSION_TOKEN_NAME: 'x-auth-token',
  },
  CONNECTION: {
    BASE: environment.CREDITS_API_URL,
    API: {
      ADMIN: environment.CREDITS_API_URL + 'admin/',
      COLLECTION: environment.COLLECTION_API_URL,
      IDENTITY_REPORTS: environment.IDENTITY_REPORTS_API_URL,
      PAYMENTS: environment.PAYMENTS_API_URL,
      SCORING: environment.SCORING_API_URL,
      FACE_RECOGNITION: environment.FACE_RECOGNITION_API_URL,
      CLIENT_COMMUNICATION: environment.CLIENT_COMMUNICATION_API_URL,
      P2P: environment.P2P_API_URL,
    },
    STATIC: environment.CREDITS_API_URL,
  },
  FILTERING: {
    STORAGE_TYPE: BrowserStorageType.LocalStorage,
    MULTI_VALUE_SEPARATOR: ',',
  },
  HTML_EDITOR: {
    editable: true,
    spellcheck: false,
    height: 'auto',
    minHeight: '0',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    sanitize: false,
    rawPaste: true,
    // toolbar: [
    //   [
    //     'bold',
    //     'italic',
    //     'underline',
    //     'strikeThrough',
    //     'superscript',
    //     'subscript',
    //   ],
    //   ['fontName', 'fontSize', 'color'],
    //   [
    //     'justifyLeft',
    //     'justifyCenter',
    //     'justifyRight',
    //     'justifyFull',
    //     'indent',
    //     'outdent',
    //   ],
    //   ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
    //   [
    //     'paragraph',
    //     'blockquote',
    //     'removeBlockquote',
    //     'horizontalLine',
    //     'orderedList',
    //     'unorderedList',
    //   ],
    //   ['link', 'unlink', 'image'],
    //   ['code'],
    // ],
  },
  ACCESS_DENIED_ROUTE: '/403',
  LAST_VISITED_ROUTE: 'lastVisitedRoute',
  NAVIGATION: NAVIGATION_CONFIG,
};

export interface HttpRequestOptions {
  body?: any;
  headers?: any;
  context?: HttpContext;
  observe?: any;
  params?: any;
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
  transferCache?:
    | {
        includeHeaders?: string[];
      }
    | boolean;
}
