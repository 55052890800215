import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable, of, Subject } from 'rxjs';
import { IdentityReportsRequestService } from './identity-reports-request.service';
import { Ccr, CcrListElement, DYNAMIC_CCR_SUMMARY_VARIABLES } from '../types/ccr';
import {
  DisabilityCompensationReportData,
  IdentityReport,
  ListElement,
  MIReportData,
  MaritalStatusReportData,
  Nssi51PensionerValueTypes,
  NssiTypes,
  UnemploymentCompensationReportData, PersonDataReportData, AddressReportData,
  IdentityVerificationReportData,
  FaceVerification,
} from '../types/identity-reports';
import { IpInfo } from '../types/ip-info.type';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { MaritalStatus } from '../types/marital-status';
import { ClientDocument, IdentityDocument } from '../types/client-document';
import { BrandCodes } from '../types/brand';

@Injectable()
export class IdentityReportsService {
  constructor(public request: IdentityReportsRequestService) {
  }

  onIdentityDocumentChange = new Subject<IdentityDocument[]>();

  newMiReport(
    civilId: number | string,
    idCardNumber: number | string
  ): Observable<IdentityReport<MIReportData>> {
    return this.request.post(['identity-reports', 'user', civilId, 'mi'], {
      body: { idCardNumber },
    });
  }

  getMiReportsList(civilId: number | string): Observable<ListElement[]> {
    return this.request.get(['identity-reports', 'user', civilId, 'mi']);
  }

  getMiLatestReport(civilId: number | string): Observable<IdentityReport<MIReportData>> {
    return this.request.get(['identity-reports', 'user', civilId, 'mi', 'latest']);
  }

  getMIReportById(
    civilId: number | string,
    reportId: number | string
  ): Observable<IdentityReport<MIReportData>> {
    return this.request.get([
      'identity-reports',
      'user',
      civilId,
      'mi',
      reportId,
    ]);
  }

  getEventLog(civilId: string) {
    return this.request.get(['identity-reports', 'user', civilId, 'event-log']);
  }

  getCcrList(civilId): Observable<ListElement[]> {
    return this.request.get(['identity-reports', 'user', civilId, 'ccr']);
  }

  getCcrRequestsList(civilId): Observable<ListElement[]> {
    return this.request.get(['identity-reports', 'user', civilId, 'ccr-requests']);
  }
  uploadCcrRequestsReport(civilId, report): Observable<ListElement[]> {
    console.log(civilId, report);
    return this.request.post(['identity-reports', 'user', civilId, 'upload-ccr-requests'], {
      body: {
        rows: report.rows
      }
    });
  }

  getNewCcr(civilId, source?, ttl?): Observable<any> {
    if (ttl == null) {
      ttl = undefined;
    }
    return this.request.post(['identity-reports', 'user', civilId, 'ccr'], {
      body: {
        source: source, ttl: ttl
      }
    });
  }

  getConstantByKey(key: string): Observable<number> {
    return this.request.get(['constants', key]);
  }

  newCcrRequest(civilId, source?, ttl?) {
    return this.request.post(['identity-reports', 'user', civilId, 'ccr-requests'], {
      body: {
        source: source, ttl: ttl
      }
    }
    );
  }

  getOneCcrRequest(civilId, reportId) {
    return this.request.get([
      'identity-reports',
      'user',
      civilId,
      'ccr-requests',
      reportId,
    ]);
  }

  getOneCcr(civilId, reportId): Observable<any> {
    return this.request.get([
      'identity-reports',
      'user',
      civilId,
      'ccr',
      reportId,
    ]);
  }

  getDynamicCCRSummary(reportId: number): Observable<any> {
    const body = {
      body: {
        variables: DYNAMIC_CCR_SUMMARY_VARIABLES
      }
    }
    return this.request.post([
      'identity-reports',
      'ccr',
      reportId,
      'dynamic-summary'
    ], body);
  }

  getSummarizedCCR(civilId, reportId): Observable<any> {
    return this.request.get([
      'identity-reports',
      'user',
      civilId,
      'ccr',
      reportId,
      'summary'
    ]);
  }

  getNssiReportList$(civilId: number | string): Observable<any> {
    return this.request.get(['identity-reports', 'user', civilId, 'nssi'], {
      params: {
        expand: 'type',
      },
    });
  }

  getCompensationReportList$(civilId: number | string): Observable<any> {
    return this.request.get(['identity-reports', 'user', civilId, 'compensation'], {
      params: {
        expand: 'type',
      },
    });
  }

  getCompensationReportById(civilId, id, type): Observable<any> {
    return this.request.get(['identity-reports', 'user', civilId, type, id]);
  }

  getNssiReportById(civilId, id, type): Observable<any> {
    return this.request.get(['identity-reports', 'user', civilId, type, id]);
  }

  getSummarizedNssiReportById(civilId, id, type): Observable<any> {
    return this.request.get(['identity-reports', 'user', civilId, type, id, 'summary']);
  }

  getNewNssiByType(civilId, type, source: string, cache): Observable<any> {
    if (cache == null) {
      cache = undefined;
    }
    const body = {
      body: {
        source: source,
        ttl: cache
      }
    };
    return this.request.post(['identity-reports', 'user', civilId, type], body);
  }

  getMaritalStatusReport(civilId): Observable<any> {
    return this.request.post(['identity-reports', 'user', civilId, 'marital-status']);
  }

  getMaritalStatusReportList(civilId: number | string): Observable<ListElement[]> {
    return this.request.get(['identity-reports', 'user', civilId, 'marital-status']);
  }

  getMaritalStatusReportById(
    civilId: number | string,
    reportId: number | string
  ): Observable<IdentityReport<MaritalStatusReportData>> {
    return this.request.get([
      'identity-reports',
      'user',
      civilId,
      'marital-status',
      reportId,
    ]);
  }

  getDisabilityCompensationReport(civilId, dateFrom: string): Observable<any> {
    return this.request.post(['identity-reports', 'user', civilId, 'disability-compensation'], {
      body: {
        dateFrom: dateFrom,
        dateTo: moment().format('YYYY-MM-DD')
      },
    });
  }

  getDisabilityCompensationReportList(civilId: number | string): Observable<ListElement[]> {
    return this.request.get(['identity-reports', 'user', civilId, 'disability-compensation']);
  }

  getDisabilityCompensationReportById(
    civilId: number | string,
    reportId: number | string
  ): Observable<IdentityReport<DisabilityCompensationReportData>> {
    return this.request.get([
      'identity-reports',
      'user',
      civilId,
      'disability-compensation',
      reportId,
    ]);
  }

  getUnemploymentCompensationReport(civilId, dateFrom: string): Observable<any> {
    return this.request.post(['identity-reports', 'user', civilId, 'unemployment-compensation'], {
      body: {
        dateFrom: dateFrom ? dateFrom : moment().subtract(1, 'years').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD')
      },
    });
  }

  getUnemploymentCompensationReportList(civilId: number | string): Observable<ListElement[]> {
    return this.request.get(['identity-reports', 'user', civilId, 'unemployment-compensation']);
  }

  getUnemploymentCompensationReportById(
    civilId: number | string,
    reportId: number | string
  ): Observable<IdentityReport<UnemploymentCompensationReportData>> {
    return this.request.get([
      'identity-reports',
      'user',
      civilId,
      'unemployment-compensation',
      reportId,
    ]);
  }

  getPersonDataReport(civilId): Observable<any> {
    return this.request.post(['identity-reports', 'user', civilId, 'person-data']);
  }

  getPersonDataReportById(
    civilId: number | string,
    reportId: number | string
  ): Observable<IdentityReport<PersonDataReportData>> {
    return this.request.get([
      'identity-reports',
      'user',
      civilId,
      'person-data',
      reportId,
    ]);
  }

  getPersonDataReportList(civilId: number | string): Observable<ListElement[]> {
    return this.request.get(['identity-reports', 'user', civilId, 'person-data']);
  }

  getAddressReport(civilId, reportType: string, dateFrom): Observable<any> {
    const addressType = reportType.replace('_', '-').toLowerCase();
    return this.request.post(['identity-reports', 'user', civilId, addressType],
      {
        body: {
          dateFrom: dateFrom
        }
      });
  }

  getAddressReportById(
    civilId: number | string,
    reportId: number | string,
    reportType: string
  ): Observable<IdentityReport<AddressReportData>> {
    const addressType = reportType.replace('_', '-').toLowerCase();
    return this.request.get([
      'identity-reports',
      'user',
      civilId,
      addressType,
      reportId,
    ]);
  }


  getAddressReportList(civilId: number | string, reportType: string): Observable<ListElement[]> {
    const addressType = reportType.replace('_', '-').toLowerCase();
    return this.request.get(['identity-reports', 'user', civilId, addressType]);
  }

  getAddressReportHistory(civilId: number | string, reportType: string): Observable<any[]> {
    const addressType = reportType.replace('_', '-').toLowerCase();
    return this.request.get(['identity-reports', 'user', civilId, addressType + '-history']);
  }

  updateAddressReportHistory(civilId: number | string, reportType: string): Observable<any[]> {
    const addressType = reportType.replace('_', '-').toLowerCase();
    return this.request.post(['identity-reports', 'user', civilId, addressType + '-history']);
  }

  getIpInfo$(ip: string): Observable<IpInfo> {
    return this.request.get(['ip-info', ip]);
  }

  // --- START IDENTITY DOCUMENTS ---
  addIdentityDocument$(
    clientCivilId: number | string,
    brandCode: BrandCodes,
    type: string,
    clientFile: any
  ): Observable<IdentityDocument> {
    return this.request.post(['identity-documents', 'users', clientCivilId, brandCode, 'documents', type], {
      body: clientFile,
    });
  }

  getIdentityDocumentList$(clientCivilId: string, brandCode: BrandCodes): Observable<IdentityDocument[]> {
    return this.request.get(['identity-documents', 'users', clientCivilId, brandCode, 'documents']);
  }

  renderIdentityDocument(
    clientCivilId: string,
    documentId: number | string
  ): Observable<IdentityDocument> {
    return this.request.get(
      ['identity-documents', 'users', clientCivilId, 'documents', documentId, 'content'],
      { responseType: 'blob' }
    );
  }

  deleteIdentityDocument(
    clientCivilId: string,
    documentId: number | string
  ): Observable<any> {
    return this.request.delete(['identity-documents', 'users', clientCivilId, 'documents', documentId]);
  }
  // --- END IDENTITY DOCUMENTS ---


  sortNssi2PersonalData(a, b) {
    const aPartials = a.inputdate.split('/');
    const aDay: any = aPartials[0];
    const aMonth = aPartials[1];
    const aYear: any = aPartials[2];
    const bPartials = b.inputdate.split('/');
    const bDay: any = bPartials[0];
    const bMonth = bPartials[1];
    const bYear: any = bPartials[2];
    const aDate = new Date(aYear, Number(aMonth) - 1, aDay);
    const bDate = new Date(bYear, Number(bMonth) - 1, bDay);
    // return aDate.getTime() - bDate.getTime();
    return a.Year - b.Year || a.Month - b.Month;
  }

  getCompanyPhones$(identifier: string): Observable<any> { //TODO add interface
    return this.request.get(['company-reports', 'phone', identifier]);
  }


  findCompanyPhones$(identifier: string): Observable<any> { //TODO add interface
    return this.request.post(['company-reports', 'phone', identifier]);
  }

  formatClientName(name) {
    if (name) {
      return name.toLowerCase().replace(/(^|\s|[-])\S/g, function (match) {
        return match.toUpperCase();
      });
    }
  }

  parseMaritalStatusCode(maritalStatusMapper: { [key: number]: string }, maritalStatusCode: number, maritalStatusList: MaritalStatus[]) {
    const mappedCode = maritalStatusMapper[maritalStatusCode];

    const maritalStatus = maritalStatusList.find(status => status.code === mappedCode);
    return maritalStatus ? maritalStatus.name : '';
  }

  parseMaritalStatusDataForUpdate(reportData, clientMapper, maritalStatuses) {
    return {
      FirstName: this.formatClientName(reportData?.FirstName),
      MiddleName: this.formatClientName(reportData?.MiddleName),
      LastName: this.formatClientName(reportData?.LastName),
      MaritalStatusName: this.parseMaritalStatusCode(
        clientMapper,
        reportData?.MaritalStatusCode,
        maritalStatuses
      )
    };
  }

  parsePersonDataDataForUpdate(reportData) {
    return {
      FirstName: this.formatClientName(reportData?.PersonNames?.FirstName),
      SurName: this.formatClientName(reportData?.PersonNames?.SurName),
      FamilyName: this.formatClientName(reportData?.PersonNames?.FamilyName),
      Nationality: reportData?.Nationality,
    };
  }

  parseCompensationDataForUpdate(clientNames) {
    if (clientNames) {
      return {
        firstName: this.formatClientName(clientNames[0]),
        middleName: this.formatClientName(clientNames[1]),
        lastName: clientNames.slice(2).map(name => this.formatClientName(name)).join(' '),
      };
    }
  }

  parseCcrDataForUpdate(clientNames) {
    if (clientNames) {
      return {
        firstName: this.formatClientName(clientNames[0]),
        middleName: this.formatClientName(clientNames[1]),
        lastName: clientNames.slice(2).map(name => this.formatClientName(name)).join(' '),
      };
    }
  }

  parseNssi2DataForUpdate(nssi2ReportData) {
    return {
      familyname: this.formatClientName(nssi2ReportData?.familyname),
      City: nssi2ReportData?.City,
      Address: nssi2ReportData?.Address,
    };
  }

  parseNssi7DataForUpdate(nssi7ReportData) {
    return {
      FirstName: this.formatClientName(nssi7ReportData?.FirstName),
      SurName: this.formatClientName(nssi7ReportData?.SurName),
      FamilyName: this.formatClientName(nssi7ReportData?.FamilyName),
    };
  }

  parseNssi51DataForUpdate(nssi51ReportData) {
    const nssi51ClientData: any = {};

    nssi51ReportData?.Pensioner.forEach(pensionerInfoObj => {
      switch (pensionerInfoObj.PensionerNameInfo) {
        case Nssi51PensionerValueTypes.FIRST_NAME:
          nssi51ClientData.firstName = this.formatClientName(pensionerInfoObj.Value);
          break;
        case Nssi51PensionerValueTypes.MIDDLE_NAME:
          nssi51ClientData.middleName = this.formatClientName(pensionerInfoObj.Value);
          break;
        case Nssi51PensionerValueTypes.LAST_NAME:
          nssi51ClientData.lastName = this.formatClientName(pensionerInfoObj.Value);
          break;
        case Nssi51PensionerValueTypes.ADDRESS:
          nssi51ClientData.address = pensionerInfoObj.Value;
          break;
        case Nssi51PensionerValueTypes.TOTAL_PENSION_AMOUNT:
          nssi51ClientData.totalAmount = Math.floor(Number(pensionerInfoObj.Value));
          break;
      }
    });

    return nssi51ClientData;
  }

  getIdentityVerificationReportById$(civilId: number | string, brandCode: BrandCodes, reportId: number): Observable<IdentityReport<IdentityVerificationReportData>> {
    return this.request.get(['identity-reports', 'user', civilId, 'identity-verification', brandCode, reportId]);
  }

  getIdentityVerificationReportList(civilId: number | string, brandCode: BrandCodes): Observable<ListElement[]> {
    return this.request.get(['identity-reports', 'user', civilId, 'identity-verification', brandCode]).pipe(
      map((reportData) => {
        for (const listData of reportData) {
          const time = new Date(listData.time);
          listData.time = time;
        }
        return reportData;
      })
    );
  }

  getLatestIdentityVerificationReport$(civilId: number | string,  brandCode: BrandCodes): Observable<IdentityReport<IdentityVerificationReportData>>  {
    return this.request.get(['identity-reports', 'user', civilId, 'identity-verification', brandCode, 'latest']);
  }

  getLatestFaceVerificationReport$(civilId: string, faceSourceId: string | number, documentSourceId: string | number): Observable<FaceVerification> {
    return this.request.get(['identity-verification', 'offsite', 'latest'], {
      params: {
        civilId, faceSourceId, documentSourceId
      }
    });
  }

}
